import ymaps from "ymaps";

// import YouTubePlayer from "youtube-player";
import LazyLoad from "vanilla-lazyload";
import videoInit from './lib/video';

import {Swiper, Pagination, Navigation} from 'swiper';

Swiper.use([Pagination, Navigation]);

let swipers = [];
let LazyLoadInstances = [];

function LazyLoadUpdate() {
  LazyLoadInstances.forEach(instance => {
    instance.update();
  })
}

function swipersUpdate() {
  swipers.forEach(sw => {
    sw.update();
  })
}

export default function init() {
  document.addEventListener('DOMContentLoaded', () => {
    // lazy loading images
    LazyLoadInstances.push(new LazyLoad({
      elements_selector: ".lazy-load",
      thresholds: "500px 10%"
    }));

    // сброс фокуса с кнопок после клика
    document.addEventListener('click', (event) => {
      if (event.target.matches('button')) {
        event.target.blur();
      }
    }, false);

    // скролл до хеша по id
    document.addEventListener('click', (e) => {
      const {target} = e;
      if (target.matches('a[href^="#"]') || target.closest('a[href^="#"]')) {
        let tg = target;
        if (target.closest('a[href^="#"]')) {
          tg = target.closest('a[href^="#"]');
        }
        const href = tg.getAttribute('href');
        if (href.length > 1) {
          const targetBlock = document.querySelector(href);
          if (targetBlock) {
            e.preventDefault();
            if (targetBlock.id) {
              window.history.pushState(null, document.title, `#${targetBlock.id}`);
            }
            targetBlock.scrollIntoView({block: 'start', behavior: 'smooth', inline: 'center'});
          }
        }
      } else if (target.matches('button[data-target^="#"],button[data-target^="."]') || target.closest('button[data-target^="#"],button[data-target^="."]')) {
        let tg = target;
        if (target.closest('button[data-target^="#"],button[data-target^="."]')) {
          tg = target.closest('button[data-target^="#"],button[data-target^="."]');
        }
        const href = tg.getAttribute('data-target');
        if (href.length > 1) {
          const targetBlock = document.querySelector(href);
          if (targetBlock) {
            e.preventDefault();
            if (targetBlock.id) {
              window.history.pushState(null, document.title, `#${targetBlock.id}`);
            }
            targetBlock.scrollIntoView({block: 'start', behavior: 'smooth', inline: 'center'});
          }
        }
      }
    });


    const map = document.querySelector('.map');
    if (map) {
      function mapCallback() {
        ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then(maps => {
          // --------------- map 1 ----------------- //
          var myMap = new maps.Map("map", {
            center: [57.6266, 39.8938],
            zoom: 14,
            controls: []
          });

          var myGeoObjects = [];

          // Музей Орлова
          myGeoObjects[0] = new maps.Placemark([57.626402, 39.899217], {
            balloonContentBody: 'Музей им. Вадима Орлова',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/museum.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Стрелка
          myGeoObjects[1] = new maps.Placemark([57.618793, 39.903519], {
            balloonContentBody: 'Стрелка',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/strelka.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Шоу-макет "Золотое кольцо"
          myGeoObjects[2] = new maps.Placemark([57.628070, 39.896389], {
            balloonContentBody: 'Шоу-макет «Золотое кольцо»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/museum.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Военный госпиталь
          myGeoObjects[3] = new maps.Placemark([57.623494, 39.893939], {
            balloonContentBody: 'Военный госпиталь',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/hospital.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Нулевой километр
          myGeoObjects[4] = new maps.Placemark([57.623010, 39.891238], {
            balloonContentBody: 'Нулевой километр',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/null.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Волжская набережная
          myGeoObjects[5] = new maps.Placemark([57.628183, 39.899236], {
            balloonContentBody: 'Волжская набережная',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/quay.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Ярославский музей-заповедник
          myGeoObjects[6] = new maps.Placemark([57.622060, 39.887004], {
            balloonContentBody: 'Ярославский музей-заповедник',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/kreml.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Демидовский столп
          myGeoObjects[7] = new maps.Placemark([57.625030, 39.896251], {
            balloonContentBody: 'Демидовский столп',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/stolp.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          var clusterer = new maps.Clusterer({
            clusterDisableClickZoom: false,
            clusterOpenBalloonOnClick: false,
          });

          clusterer.add(myGeoObjects);
          myMap.geoObjects.add(clusterer);
          myMap.behaviors.disable('scrollZoom');
          myMap.behaviors.disable('drag');

          var geometry = [[57.626291, 39.899994], [57.624258, 39.902070], [57.622905, 39.903324], [57.617940, 39.905728], [57.617572, 39.904869], [57.617710, 39.903968], [57.618401, 39.903260], [57.618896, 39.903346], [57.619564, 39.903475], [57.619448, 39.903453], [57.619967, 39.903410], [57.620347, 39.903153], [57.620842, 39.902638], [57.621152, 39.902080], [57.621475, 39.901415], [57.621751, 39.900664], [57.621993, 39.899891], [57.622246, 39.898861], [57.622361, 39.897681], [57.622442, 39.896565], [57.622430, 39.895149], [57.622131, 39.893583], [57.621866, 39.892252], [57.620865, 39.890278], [57.620657, 39.889913]];

          let properties = {
              hintContent: ""
            },
            options = {
              draggable: true,
              strokeColor: '#000000',
              strokeWidth: 1,
              strokeStyle: 'dash'

            },
            polyline = new maps.Polyline(geometry, properties, options);

          myMap.geoObjects.add(polyline);


          // --------------- map 2 ----------------- //
          var myMap2 = new maps.Map("map2", {
            center: [57.639858, 39.901386],
            zoom: 12,
            controls: []
          });

          var myGeoObjects2 = [];

          // Золотое кольцо
          myGeoObjects2[0] = new maps.Placemark([57.616813, 39.867502], {
            balloonContentBody: 'Колесо обозрения «Золотое кольцо»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/strelka.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Эмалис
          myGeoObjects2[1] = new maps.Placemark([57.639858, 39.901386], {
            balloonContentBody: 'Музей эмальерного искусства «Эмалис»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/museum.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          // Конный театр «На Бродвее»
          myGeoObjects2[2] = new maps.Placemark([57.641775, 39.930842], {
            balloonContentBody: 'Конный театр «На Бродвее»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/museum.svg',
            iconImageSize: [30, 30],
            iconImageOffset: [0, 0]
          });

          var clusterer2 = new maps.Clusterer({
            clusterDisableClickZoom: false,
            clusterOpenBalloonOnClick: false
          });

          clusterer2.add(myGeoObjects2);
          myMap2.geoObjects.add(clusterer2);
          myMap2.behaviors.disable('scrollZoom');
          myMap2.behaviors.disable('drag');


          // --------------- map all ----------------- //
          var myMap3 = new maps.Map("map3", {
            center: [57.6266, 39.8938],
            zoom: 13,
            controls: []
          });

          var myGeoObjects3 = [];

          // Музей Орлова
          myGeoObjects3[0] = new maps.Placemark([57.626402, 39.899217], {
            balloonContentBody: 'Музей им. Вадима Орлова',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Стрелка
          myGeoObjects3[1] = new maps.Placemark([57.618793, 39.903519], {
            balloonContentBody: 'Стрелка',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Шоу-макет "Золотое кольцо"
          myGeoObjects3[2] = new maps.Placemark([57.628070, 39.896389], {
            balloonContentBody: 'Шоу-макет «Золотое кольцо»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Военный госпиталь
          myGeoObjects3[3] = new maps.Placemark([57.623494, 39.893939], {
            balloonContentBody: 'Военный госпиталь',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Нулевой километр
          myGeoObjects3[4] = new maps.Placemark([57.623010, 39.891238], {
            balloonContentBody: 'Нулевой километр',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Волжская набережная
          myGeoObjects3[5] = new maps.Placemark([57.628183, 39.899236], {
            balloonContentBody: 'Волжская набережная',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Ярославский музей-заповедник
          myGeoObjects3[6] = new maps.Placemark([57.622060, 39.887004], {
            balloonContentBody: 'Ярославский музей-заповедник',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Демидовский столп
          myGeoObjects3[7] = new maps.Placemark([57.625030, 39.896251], {
            balloonContentBody: 'Демидовский столп',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Золотое кольцо
          myGeoObjects3[8] = new maps.Placemark([57.616813, 39.867502], {
            balloonContentBody: 'Колесо обозрения «Золотое кольцо»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Эмалис
          myGeoObjects3[9] = new maps.Placemark([57.639858, 39.901386], {
            balloonContentBody: 'Музей эмальерного искусства «Эмалис»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          // Конный театр «На Бродвее»
          myGeoObjects3[10] = new maps.Placemark([57.641775, 39.930842], {
            balloonContentBody: 'Конный театр «На Бродвее»',
            hideIcon: false,
          }, {
            iconLayout: 'default#image',
            iconImageHref: '/img/balloon.svg',
            iconImageSize: [19, 29],
            iconImageOffset: [0, -10]
          });

          for (var i = 0; i < myGeoObjects3.length; i++) {
            myMap3.geoObjects.add(myGeoObjects3[i]);
          }

          myMap3.behaviors.disable('scrollZoom');
        }).catch(error => console.log('Failed to load Yandex Maps', error));
      }

      window.addEventListener("scroll", ll, {capture: false, passive: true});
      window.addEventListener("mousemove", ll, {capture: false, passive: true});
      window.addEventListener("touchmove", ll, {capture: false, passive: true});
      window.addEventListener("resize", ll, {capture: false, passive: true});

      function ll() {
        window.removeEventListener("scroll", ll, false);
        window.removeEventListener("mousemove", ll, false);
        window.removeEventListener("touchmove", ll, false);
        window.removeEventListener("resize", ll, false);
        if (document.readyState === 'complete') {
          mapCallback();
        } else {
          window.addEventListener('load', mapCallback, false);
        }
      }

    }

    /* fonts animation */
    const fontItem = document.querySelectorAll('.yaroslavl span');
    changeIcon();

    function changeIcon() {
      [...fontItem].forEach((item) => {
        item.className = '';
        item.classList.add('font' + getRandomIntInclusive());
      });
      setTimeout(changeIcon, 200);
    }

    function getRandomIntInclusive(min, max) {
      min = 1;
      max = 16;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const sliders = document.querySelectorAll('.slider');
    [...sliders].forEach(slider => {
      const swiperSlider = new Swiper(slider,
        {
          wrapperClass: 'slider__wrapper',
          slideClass: 'slider__slide',
          pagination: {
            el: '.slider__pagination',
            type: 'bullets',
            clickable: true,
            bulletElement: 'button'
          },
          navigation: {
            prevEl: '.slider__arrow--prev',
            nextEl: '.slider__arrow--next',
          },
          on: {
            init(swiper) {
              videoInit(swiper.$el);
            },
            slideChange(swiper) {
              swiper.$el.find('iframe').remove();
              swiper.$el.find('.video').removeClass('video--iframe-loaded').removeClass('video--iframe-load');
            }
          }
        }
      );
      swipers.push(swiperSlider);
    })


    /* sliders */
    const places = document.querySelectorAll('.slider_places');
    [...places].forEach((slider) => {
      const swiperSlider = new Swiper(slider,
        {
          wrapperClass: 'slider_places__wrapper',
          slideClass: 'slider_places__slide',
          slidesPerView: 'auto'
        }
      );
      swipers.push(swiperSlider);
    });
    //--


    /* buttons */
    const btnDays = document.querySelectorAll('.btn_days');
    const d1Blocks = document.querySelectorAll('.is_d1');
    const d2Blocks = document.querySelectorAll('.is_d2');
    [...btnDays].forEach(day => {
      day.addEventListener('click', e => {
        const tg = e.currentTarget;
        [...btnDays].forEach(dy => {
          dy.classList.remove('active');
        });
        tg.classList.add('active');

        if (tg.classList.contains('day_1st')) {
          [...d1Blocks].forEach(d1 => {
            d1.classList.remove('hidden_important')
          });
          [...d2Blocks].forEach(d2 => {
            d2.classList.add('hidden_important')
          })
        } else {
          [...d1Blocks].forEach(d1 => {
            d1.classList.add('hidden_important')
          });
          [...d2Blocks].forEach(d2 => {
            d2.classList.remove('hidden_important')
          })
        }
        LazyLoadUpdate();
        swipersUpdate();
      });
    })


    /* show/hide text */
    const arrows = document.querySelectorAll('.arrow');
    [...arrows].forEach(arrow => {
      arrow.addEventListener('click', (ev) => {
        const tg = ev.currentTarget;
        const info = tg.parentElement.querySelector('.info');
        if (info) {
          info.classList.toggle('info--small');
          tg.classList.toggle('arrow--active');
        }
      })
    })
    //--


    /* show map */
    const fixedLink = document.querySelector('.fixed-link');
    const mapPP = document.querySelector('.map-pp');
    if (fixedLink && mapPP) {
      fixedLink.addEventListener('click', () => {
        mapPP.classList.add('show_map');
      })
    }
    const mapClose = document.querySelector('.map_close');
    if (mapClose && mapPP) {
      mapClose.addEventListener('click', () => {
        mapPP.classList.remove('show_map');
      })
    }
    //--
    LazyLoadInstances.push(new LazyLoad({
      elements_selector: ".likely",
      thresholds: "500px 10%",
      unobserve_entered: true, // <- Avoid executing the function multiple times
      callback_enter() {
        import('ilyabirman-likely').then(({default: likely}) => {
          likely.initiate();
        })
      } // Assigning the function defined above
    }));
  });

  window.addEventListener('load', () => {
    // force update lazy-load instance
    LazyLoadUpdate();
  });
}
