export default function (node) {
  const selector = '.video';
  const blocks = node.find(selector);
  blocks.each(block => {
    const btn = block.querySelector('.video__btn');
    btn.addEventListener('click', ev => {
      block.classList.add('video--iframe-load');
      const iframe = document.createElement('iframe');
      let link = block.dataset.frame;
      if (link.indexOf('youtu') !== -1 && link.indexOf('autoplay') == -1) {
        link = link + "&autoplay=1";
      }
      iframe.src = link;
      iframe.addEventListener('load',ev =>{
        block.classList.add('video--iframe-loaded');
      });
      block.appendChild(iframe);
    })
  })
}
